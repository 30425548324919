#introduction {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;

  .header-wrapper {
    left: 10vw;
    position: absolute;
    text-align: left;
    top: 10vh;

    span {
      display: block;
    }

    .header {
      font-size: 18px;
      letter-spacing: 6px;
      text-transform: uppercase;
    }

    .sub-header {
      color: #969696;
      font-size: 14px;
    }
  }

  .logo {
    color: #333333;
    display: inline-block;
    font-size: 20px;
    overflow: hidden;
    position: relative;
    width: 200px;

    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  .menu-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 10vw;
    height: 100vh;
    background-color: white;
    box-shadow: 0 15px 100px rgba(0, 0, 0, 0.06);

    ul {
      width: 100vh;
      padding-left: 10vh;
      transform: rotate(90deg);
      transform-origin: left top 0;

      li {
        position: relative;
        top: -30px;
        float: left;
        font-size: 12px;
        text-transform: uppercase;
        padding: 0px 15px;
        letter-spacing: 3px;
        background: linear-gradient(to right, gainsboro 50%, white 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition-duration: 1000ms;
        border-left: 1px solid #323232;

        &:hover {
          background-position: left bottom;
          transition-duration: 500ms;
        }

        &::first-child {
          border: none;
        }
      }
    }
  }
}

#service {
  position: relative;
  display: block;
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 14px;
  background-color: white;

  .left-container {
    position: relative;
    display: inline-block;
    left: 20vw;
    width: 35vw;
    text-align: right;
    vertical-align: middle;

    .header {
      opacity: 0.1;
      font-size: 80px;
    }
  }
  .right-container {
    position: relative;
    display: inline-block;
    left: 25vw;
    width: 35vw;
    vertical-align: middle;
    overflow: hidden;

    ul li {
      margin: 10px 0;
      transition-duration: 500ms;
    }
  }
}

#history {
  position: relative;
  text-align: center;
  overflow: hidden;

  .wrapper {
    &-left {
      position: relative;
      right: calc(50% + 20px);
      text-align: right;

      .pointer {
        color: gray;
        position: absolute;
        right: -28px;
        top: 12px;
      }
    }
    &-right {
      position: relative;
      left: calc(50% + 20px);
      text-align: left;

      .pointer {
        color: gray;
        position: absolute;
        left: -28px;
        top: 12px;
      }
    }
  }
  .wrapper-left,
  .wrapper-right {
    .header {
      font-size: 30px;
      opacity: 0.1;
      position: relative;
    }
    .location {
      color: lightgrey;
      margin-bottom: 10px;
    }
  }

  .timeline {
    border-left: 2px solid #323232;
    height: 1400px;
    left: calc(50% - 1px);
    margin-top: 20px;
    position: absolute;
  }
}

#project {
  margin-top: 20vh;

  .header {
    position: relative;
    display: inline-block;
    left: 10vw;
    opacity: 0.1;
    font-size: 80px;
  }

  .wrapper-project {
    position: relative;
    display: block;
    padding: 80px 10vw;
    border-bottom: 1px solid gainsboro;
    text-align: center;
    background: linear-gradient(to right, gainsboro 30%, white 60%);
    background-size: 300% 100%;
    background-position: right bottom;
    transition-duration: 1500ms;
    overflow: hidden;

    &:nth-child(2) {
      border-top: 1px solid gainsboro;
    }

    &:hover {
      transition-duration: 1500ms;
      background-position: left bottom;
      .project-preview {
        transition-delay: 0.5s;
        transition-duration: 1s;
        opacity: 1;
      }
    }

    .project-header {
      font-size: 20px;
    }

    .project-sub-header {
      color: #969696;
      font-size: 12px;
      animation-duration: 1200ms;
    }

    .project-preview {
      position: absolute;
      opacity: 0;
      top: 0;
      left: calc(50vw - 200px);
      width: 400px;
      transition-delay: 200ms;
      transition-duration: 500ms;
    }
  }
}

#footer {
  position: relative;
  margin-top: 10vh;
  height: 250px;
  text-align: center;

  .left-container,
  .right-container {
    color: #969696;
    display: inline-block;
    vertical-align: top;
    width: 40vw;

    .header {
      color: #323232;
      padding-bottom: 20px;
    }
  }

  .copyright {
    margin-top: 80px;
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: "Abel", sans-serif;
  margin: 0;
  padding: 0;

  .loading-wrapper {
    align-items: center;
    background-color: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    position: fixed;
    width: 100vw;

    .loading-gif {
      height: 100px;
      width: 100px;
    }
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
